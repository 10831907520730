<template>
  <div
    :id="'country_select_title-' + country"
    :aria-label="$t('country_select.title')"
    role="button"
    tabindex="0"
    class="cursor-pointer flex items-center"
    data-test="gmp_preferences"
    @click.stop="$emit('locale:click')"
  >
    <flag :country="country" class="me-2 rounded-sm" />
    <p aria-hidden="true" :class="localeClasses">
      {{ text }}
    </p>
  </div>
</template>
<style scoped>
[dir='rtl'] .languageCodeRtl {
  position: relative;
  top: 2px;
}
.nbsp {
  white-space: nowrap;
}
</style>
<script>
import { mapGetters } from 'vuex'
import { Flag } from '~/components/atoms'

export default {
  components: {
    Flag,
  },
  props: {
    country: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      default: null,
    },
    showCountryText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('context', ['direction', 'language', 'isGlobalMarketplace']),
    localeClasses() {
      return [
        'nbsp',
        'hover:underline text-lg',
        this.direction === 'rtl' ? 'languageCodeRtl' : '',
      ]
    },
    text() {
      if (!this.isGlobalMarketplace) {
        return this.lang
      }
      const countryAsText = this.showCountryText ? this.country : null

      return [countryAsText, this.language.displayLanguage, this.currency]
        .filter(Boolean)
        .join(' | ')
        .toUpperCase()
    },
  },
}
</script>
